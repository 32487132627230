
import Disciplina from "@/interfaces/Disciplina";
import router from "@/router";
import DisciplinaService from "@/services/admin/DisciplinaService";
import ContaService from "@/services/conta/ContaService";
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const disciplinas = ref<Disciplina[]>();
    const goTo = (rota: string): void => {
      router.push(rota);
    };

    const getAllDisciplinas = (): void => {
      DisciplinaService.list()
        .then((res) => {
          disciplinas.value = res.data.filter((d: Disciplina) =>
            d.nome.includes("reda") || d.nome.includes("por")
          );;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar disciplinas",
          });
        })
    };

    const getMyDisciplinas = (): void => {
      console.log()
      ContaService.get()
        .then((res) => {
          disciplinas.value = res.data.disciplinas?.filter((d: Disciplina) =>
            d.nome.includes("reda") || d.nome.includes("por")
          );
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar disciplinas",
          });
        });
    };

    onMounted(() => {
      if (store.getters.userPerfil == 'administrador') {
        getAllDisciplinas()
      } else {
        getMyDisciplinas()
      }
    });

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return { disciplinas, goTo, store };
  },
});
